<template>
  <div class="picker-page">
    <el-button class="picker-btn" type="primary" icon="el-icon-location" @click="pickerShow = !pickerShow"></el-button>
    <el-dialog title="选择坐标" :visible.sync="pickerShow" width="70%" :close-on-click-modal="false" @open="mapInit" :close-on-press-escape="false" :before-close="handleClose">
      <div class="dialog-content">
        <div class="picker-header">
          <div class="item">
            <div class="title">经纬度：</div>
            <div class="content">{{lnglat}}</div>
          </div>
          <div class="item">
            <div class="title">地址：</div>
            <div class="content">{{address}}</div>
          </div>
          <div class="config-btn">
            <el-button type="primary" size="mini" @click="configAddress">确 认</el-button>
          </div>
        </div>
        <div class="picker-content">
          <div id="map"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'CoordPicker',
  data () {
    return {
      pickerShow: false,
      address: '',
      lnglat: '',
      map: null,
      marker: new window.AMap.Marker(),
      geocoder: null
    }
  },
  mounted () {
  },
  methods: {
    mapInit () {
      this.$nextTick(() => {
        this.map = new window.AMap.Map('map', {
          resizeEnable: true
        })

        this.geocoder = new window.AMap.Geocoder({
          city: '010', // 城市设为北京，默认：“全国”
          radius: 1000 // 范围，默认：500
        })
        this.map.on('click', (e) => {
          console.log(e.lnglat)
          this.lnglat = e.lnglat.lng + ',' + e.lnglat.lat
          this.regeoCode()
        })
      })
    },
    regeoCode () {
      const lnglats = this.lnglat.split(',')
      this.map.add(this.marker)
      this.marker.setPosition(lnglats)
      this.geocoder.getAddress(lnglats, (status, result) => {
        if (status === 'complete' && result.regeocode) {
          this.address = result.regeocode.formattedAddress
        } else {
          console.log('根据经纬度查询地址失败')
        }
      })
    },
    handleClose () {
      this.pickerShow = false
    },
    // 确认选取的坐标
    configAddress () {
      this.$emit('configAddress', { lnglat: this.lnglat, address: this.address })
      this.pickerShow = false
    }
  }
}
</script>

<style scoped>
.picker-page{
  position: relative;
}
.picker-btn{
  margin-left: 10px;
}
.picker-page .picker-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
}
.picker-page .picker-header .item{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.picker-page .dialog-content{
  box-sizing: border-box;
  background-color: #ddd;
  padding: 15px;
  height: 600px;
  display: flex;
  flex-direction: column;
}
.picker-content {
  flex: 1;
}
.picker-content #map{
  width: 100%;
  height: 100%;
}
</style>
