<template>
  <div class="add-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.meetingName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/traffic/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } }">交通路线管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加交通路线</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form :model="addForm" :rules="addRules" ref="addRef" class="add-form" label-position="left" label-width="100px">
        <el-form-item label="排序" prop="sort">
          <el-input-number size="medium" v-model="addForm.sort" :min="0" :step-strictly="true"></el-input-number>
        </el-form-item>
        <el-form-item label="地标名称" prop="title">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="mark">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="请输入备注内容" v-model="addForm.mark"></el-input>
        </el-form-item>
        <el-form-item label="地标坐标" prop="coord">
          <div class="flex-input">
            <el-input v-model="addForm.coord" placeholder="请点击右侧按钮选取坐标"></el-input>
            <coord-picker @configAddress="configAddress"></coord-picker>
          </div>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="addForm.address"></el-input>
        </el-form-item>
        <el-form-item label="是否需要权限" prop="is_auth">
          <el-radio-group v-model="addForm.is_auth">
            <el-radio :label="1">需要</el-radio>
            <el-radio :label="2">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="cancleForm">取消添加</el-button>
          <el-button type="primary" @click="submitForm">立即创建</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import CoordPicker from '../../../../common/CoordPicker'
export default {
  name: 'Add',
  components: {
    CoordPicker
  },
  data () {
    return {
      addForm: {
        mId: this.$route.params.mId,
        sort: 0,
        title: '',
        mark: '',
        coord: '',
        address: '',
        is_auth: 2
      },
      addRules: {
        title: [
          { required: true, message: '请填写交通路线地标名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请填写交通路线详细地址', trigger: 'blur' }
        ],
        coord: [
          { required: true, message: '请填写交通路线地标坐标', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 坐标选取返回
    configAddress (coordAddress) {
      this.addForm.coord = coordAddress.lnglat
      this.addForm.address = coordAddress.address
    },
    // 添加表单
    submitForm () {
      this.$refs.addRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.post('/shell-traffic/0', this.addForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.addRef.resetFields()
            await this.$router.push({ path: '/admin/apps/shell/content/traffic/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } })
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    // 取消添加
    cancleForm () {
      this.$router.push({ path: '/admin/apps/shell/content/traffic/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } })
    }
  }
}
</script>

<style scoped>
.add-form{
  width: 650px;
  margin-left: auto;
  margin-right: auto;
}
.flex-input{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex-input .el-button{
  margin-left: 10px;
}
</style>
